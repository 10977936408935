import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/7.png"
import Vaclav from "../images/photos/vasek.jpeg"
import Tomas from "../images/photos/tomas.jpg"

const LECTURER = [
  {
    src: Tomas,
    alt: "",
    personName: "Tomáš Kumsta",
    text: [
      "Tomáš se dlouhodobě zaměřuje na datovou analytiku napojenou především na online byznys. Profesní zkušenosti nabíral hlavně v Medio Interactive (RIP) a Seznam.cz.",
      "Momentálně působí v Livesportu, kde se aktivně věnuje velkým datům a rozjezdu sportovního zpravodajství. To ho zavedlo do hlubin GA4 360 a BigQuery. Doufá, že se vynoří a zůstane příčetný, aby mohl dál na workshopech předávat své zkušenosti.",
    ],
    linkedInProfile: "//linkedin.com/in/tkumsta",
  },
  {
    src: Vaclav,
    alt: "",
    personName: "Václav Ráš",
    text: [
      "Vašek pracuje s daty především na databázové úrovni. Baví ho jak datová čistota a automatizované datové transformace v BigQuery, tak složitější analýzy v Pythonu.",
      "Využívá zkušeností, které nabral ve velkých korporátech, jako jsou O2, Creditas či Česká televize, ale i u menších startupů, jako je například Qerko.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavras/",
  },
]
const DatovaArchitektura: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Datová architektura" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Datová architektura"
        numberOfLesson="7/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          práce a skladování dat v BigQuery
          <div>users in BQ (security,..)</div>
          <div>obnova dat (smazané / změněné tabulky)</div>
          <div>
            lokace dataset (US / EU) – rozdíl v ceně, rychlosti,
            nekompatibilitě, ale i ve funkcích
          </div>
          <div>data transfery (lokace)</div>
        </div>

        <div>
          transformace dat
          <div>typy tabulek/views a jejich využití v praxi</div>
          <div>
            výběry dat dle použití
            <div>
              rozdělení dat na menší celky podle eventů a platforem – odlišné
              zpracování, napojení a práce
            </div>
          </div>
          <div>
            metrika „Users” in BQ
            <div>
              HyperLogLog++ (HLL++) – inkrementální zpracování distinctových dat
            </div>
            <div>jak ji používat</div>
            <div>nevýhody pro současný reporting</div>
            <div>příklady dalších distinct metrik</div>
          </div>
          <div>
            agregace
            <div>různé úrovně agregací</div>
            <div>
              předpřipravení a omezení úrovně agregací – vhodné především pro
              unikátní (a jiné nesoučtové) metriky
            </div>
          </div>
          <div>
            scheduled queries přímo v BQ / mimo BQ (Dataform, DBT)
            <div>scheduled queries a jejich využití i nevýhody</div>
            <div>
              Dataform – současná podoba, ukázka bety (zřejmě nepraktická)
            </div>
          </div>
        </div>

        <div>
          obohacování dat (propojování s mimo webovými daty)
          <div>Práce s dataset / G Ads transferem</div>
          <div>
            napojení – pomalu se měnící dimenze v čase
            <div>Částečně součást předchozího tématu</div>
          </div>
          <div>CRM</div>
          <div>mediální data (Ads dataset)</div>
          <div>CDP</div>
        </div>

        <div>
          verzování (GIT / Dataform / DBT) - optional
          <div>proč verzovat</div>
          <div>základní plusy a mínusy</div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default DatovaArchitektura
